@import 'layout/_layout';

@import 'scafholdings/_scafholding';
// Header
@import 'loader/_spinner';
// Header
@import 'topbar/_header';

//table pages
@import 'tables/_table-pages';
// breadcrumb
@import 'breadcrumb/_breadcrumb';

// sidebar
@import 'sidebar/_sidebar';

//wave effects
@import 'wave-effects/_wave-effects';

//wave effects
@import 'buttons/_buttons';

//wave effects
@import 'customizer/_customizer';

//Animation effects
@import 'animation/_animation';

//perfect-scrollbar
@import 'perfect-scrollbar/_perfect-scrollbar';


//extra
@import 'extra/_extra';
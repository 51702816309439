/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/



/*******************
Table Cell
*******************/

.table-box {
    display: table;
    width: 100%;
}

.cell {
    display: table-cell;
    vertical-align: middle;
}

.table thead th,
.table th {
    font-weight: 500;
}

.nowrap {
    white-space: nowrap;
}

.lite-padding td {
    padding: 5px;
}

.v-middle {
    td,
    th {
        vertical-align: middle;
    }
}

/*******************
Table td vertical middle
*******************/

.vm.table td,
.vm.table th {
    vertical-align: middle;
}

.no-th-brd.table th {
    border: 0px;
}

.table.no-border {
    tbody {
        td {
            border: 0px;
        }
    }
}



/*******************
Table-Jsgrid table
******************/

.jsgrid-pager-page a,
.jsgrid-pager-current-page,
.jsgrid-pager-nav-button a {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: #67757c;
    border: 1px solid #ddd;
}

.jsgrid-pager-page a:hover,
.jsgrid-pager-nav-button a:hover {
    background-color: $themecolor;
    color: $white;
}

.jsgrid-pager-current-page {
    background-color: $themecolor;
    color: $white;
}

.jsgrid-pager-page,
.jsgrid-pager-nav-button {
    padding: 0;
}

.jsgrid-pager-page.jsgrid-pager-current-page {
    padding: 0.5em 1em !important;
}
$label-color: #86939e;
$input-border-color: #e1e2e3;

.form-control {
  height: 40px;
  border-color: $input-border-color;
  border-radius: 4px;
}

.form-control-lg,
.btn-lg{
  font-size: 1rem !important;
}

.form-spaced-3{
  .form-group{
    margin-bottom: 1.5rem;
  }
}

.form-group{
  margin-top: 0;
  margin-bottom: 1rem;
}

.input-group-append{
  height: 40px;
}

.form-check-input{
  width: 16px;
  height: 16px;
  margin-right: 15px;
}
.form-check-label{
  margin-left: 5px;
}

label{
  color: $label-color;
  font-size: 0.9rem;
}

.form-light{
  .form-control, select {
    font-size: 0.9rem;
    color: #8e9aa4;
    background-color: #f2f6f7;
    border: 1px solid #e4e9ef;

    &:focus{
      color: $se-dark-blue;
      background-color: white;
    }
  }

  .input-group-text{
    color: #8e9aa4;
    background-color: #f2f6f7;
    border: 1px solid #e4e9ef;
  }
}
/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/

@import '_topbar-mail';
@import '_comments';
@import '_chat-widgets';
@import '_chat-popup';
@import '_steamline';
@import '_feeds';
@import '_task';
@import '_profile';
/*******************************/

// Custom Variables

/*******************************/

@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');



/*Theme Colors*/
//SE Colors
$se-blue: #04909f;
$se-dark-blue: #334A5F;
$themecolor: $se-blue;
$themecolor-dark: $se-blue;
$themecolor-alt: #26c6da;
$theme-light:#E1E2E3;
$theme-dark:#212529;

$skin1:$se-blue;
$skin2:#fe5419;
$skin3:#00b0ff;
$skin4:$se-blue;
$skin5:$se-blue;
$skin6:#fff;



/*Topbar Colors*/
$topbar:$se-blue;
$topbar-height:64px;
$topbar-navlink-padding:0px 15px;
$topbar-navlink-font-size:0.875rem;
$topbar-navlink-height:64px;
$topbar-navbrand-padding:0 10px 0 10px;



/*Sidebar Colors*/
$sidebar: $theme-light;
$sidebar-alt:#a6b7bf ;
$sidebar-text: #fff;
$sidebar-icons: #fff;
$sidebar-text-dark: #a6b7bf ;
$sidebar-icons-dark: #a6b7bf ;

$sidebar-width-full:250px;
$sidebar-width-iconbar:180px;
$sidebar-width-mini:65px;   



/*Boxed layout width*/

$boxed-width:1200px;



/*Shadow*/

$shadow:1px 0px 20px rgba(0,
0,
0,
0.08);



/*transitions*/

$transitions:0.2s ease-in;



/*Dark transparent bg*/

$transparent-dark-bg:rgba(0,
0,
0,
0.05);

$lft:left;
$rgt:right;
$card-alt:#e4e9ef;

%square {
    border-radius: 0px
}

;
%rotate45 {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
}



/*******************************/

// Bootstrap overrides

/*******************************/


/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */

//
// Color system
//
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #a1aab2 !default;
$gray-600: #6c757d !default;
$gray-700: #4F5467 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #2962FF !default;
$indigo: #6610f2 !default;
$purple: #7460ee !default;
$pink: #e83e8c !default;
$red: #f62d51 !default;
$orange: #fb8c00 !default;
$yellow: #fdb858 !default;
$green: #36bea6 !default;
$teal: #20c997 !default;
$cyan: #4fc3f7 !default;
$primary: $se-blue !default;
$text-muted: $gray-500 !default;

$colors: ( blue: $blue,
indigo: $indigo,
purple: $purple,
pink: $pink,
red: $red,
orange: $orange,
yellow: $yellow,
green: $green,
teal: $teal,
cyan: $cyan,
white: $white,
gray: $gray-500,
gray-dark: $gray-800);

$primary: $se-blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: #5BC6D6 !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $se-dark-blue !default;
$cyan: $cyan !default;
$orange: $orange !default;
$theme-colors: () !default;
$theme-colors: map-merge(( "primary": $primary,
"secondary": $secondary,
"success": $success,
"info": $info,
"warning": $warning,
"danger": $danger,
"light": $light,
"dark": $dark,
"cyan": $cyan,
"orange": $orange,
"purple": $purple),
$theme-colors);


//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;


// Body
//
// Settings for the  element.
$main-body-bg: #fff!default;
$body-bg: #eef5f9;
$body-color: #272930 !default;
$grid-gutter-width: 20px !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-size-base: 1rem;
$font-family-sans-serif: 'Rubik', sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;

$h1-font-size: 36px !default;
$h2-font-size: 30px !default;
$h3-font-size: 24px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;

$headings-margin-bottom: (1rem / 2) !default;
$headings-font-weight: 700 !default;
$headings-color: inherit !default;



// Breadcrumbs
$breadcrumb-bg: $body-bg;
$breadcrumb-margin-bottom: 1.5rem;

// Cards
$card-border-width: 0px!default $card-border-color: transparent!default;

$card-border-radius: 0px !default;


// Dropdowns
$dropdown-item-padding-x: 1rem !default;
$dropdown-item-padding-y: .65rem !default;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons
$btn-secondary-border: $gray-300;

// Progress bars
$progress-bg: $gray-100;

// Tables
$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;
$table-hover-bg: $gray-100 !default;
$table-cell-padding: 1rem !default;
$table-bg-level: -10 !default;
$table-bg-level2: 1 !default;
$table-bg-level3: -5 !default;
// Components
$component-active-color: $white !default;
$component-active-bg: $themecolor !default;
$badge-pill-padding-x: .2em !default;
$badge-pill-padding-y: 1em !default;


// Forms
$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
$input-btn-focus-color: rgba(0,
0,
0,
.25) !default;
$input-focus-border-color: rgba(0,
0,
0,
0.25) !default;
$custom-control-indicator-active-bg: rgba(0,
0,
0,
35%) !default;
$custom-select-indicator: url(../../images/custom-select.png)!default;
$input-btn-focus-box-shadow: transparent !default;
$custom-select-focus-box-shadow: transparent !default;
$custom-select-indicator: str-replace(url(""),
"%23") !default;
//
// Define common padding and border radius sizes and more.
$border-width: 1px !default;
$border-color: $gray-200 !default;

$border-radius: 2px !default;
$border-radius-lg: 2px !default;
$border-radius-sm: 1px !default;


// Progress bars
$progress-height: 5px !default;

//Tabs
$nav-tabs-link-active-bg: $white !default;
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: ( xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1600px) !default;

//Navbar
$navbar-dark-color: rgba($white,
.8) !default;
$navbar-dark-hover-color: rgba($white,
1) !default;
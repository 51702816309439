@import "../../variable";

.auth-wrapper{
    min-height: 100vh;
    position: relative;
    .auth-box{
        background: $white;
        padding: 20px;
        box-shadow: $shadow;
        max-width: 500px;
        width: 90%;
        margin: 10% 0;
        .logo{
            text-align: center;
        }
        &.on-sidebar{
            top:0px;
            right: 0px;
            height: 100%;
            margin: 0px;
            position: absolute;
        }
    }
    
    #recoverform{
        display: none;
    }
    .auth-sidebar{
        position: fixed;
        height: 100%;
        right: 0px;
        overflow: auto;
        margin: 0px;
        top: 0px;
    }
}

.auth-wrapper.lg{
    .auth-box{
        max-width: 640px;
    }
}

.auth-wrapper.auth-lg.lg{
    .auth-box{
        max-width: 1200px;
    }
}

@media(max-width:767px) {
    .auth-wrapper{
        .auth-sidebar{
            position: relative;
            max-width: 100%;
            width: 100%;
            margin: 40px 0 60px;
        }
    }
}

@media(max-width:600px) {
    .auth-wrapper{
        width: 100%;
        .auth-box{
            width: 100%;

            &.on-sidebar{
                right: auto;
                left: auto;
            }
        }
    }
}
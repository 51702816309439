.global-notification {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  right: 0;

  .alert{
    opacity: 0.9;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    border-radius: 0;
    border: none;

    box-shadow: 4px 4px 5px 0px rgba(0,0,0,.2);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;

    .alert-heading{
      margin-bottom: 10px;
    }
  }
}

.alert.alert-success {
  background-color: #55BF81;
  color: white;
}

.alert.alert-danger {
  background-color: #A24000;
  color: white;
}
.badge{
  font-weight: 500;
  text-transform: uppercase;
  //padding: 8px 10px;
  letter-spacing: 1px;
}

.badge.badge-circle {
  border-radius: 100px;
}

.badge.badge-primary {
  background-color: $primary;
}

.badge.badge-danger,
.badge.badge-failed{
  color: white;
  background-color: $danger;
}

.badge.badge-warning,
.badge.badge-pending,
.badge.badge-processing,
.badge.badge-on-hold{
  color: white;
  background-color: $warning;
}

.badge.badge-info {
  color: #324b4f;
  background-color: #DBFAFF;
}

.badge.badge-deleted,
.badge.badge-cancelled,
.badge.badge-refunded{
  color: white;
  background-color: #707070;
}

.badge.badge-success,
.badge.badge-completed{
  color: white;
  background-color: $success;
}

.badge.badge-white,
.badge.badge-new {
  background-color: white;
  color: $blue !important;
}

.badge-lg{
  display: inline-block;
  font-size: 1.1rem;
  padding: 7px 10px !important;
}

.badge-order-status{
  min-height: 15px;
  min-width: 15px;
}

@media (max-width: 1200px){
  .badge-order-status{
    border-radius: 20px;
  }
}
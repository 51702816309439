@import "custom/alerts";
@import "custom/badges";
@import "custom/form";
@import "custom/tables";
@import "custom/images";

html, body, #main-wrapper, .page-wrapper{
  background: #f2f6f7 !important;
}

.display-4{
  font-size: 2.8rem;
}

.btn{
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

.btn-warning{
  color: #fff !important;
}



.se-text-logo{
  max-width: 100% !important;
  max-height: 35px !important;
  padding: 5px 5px;
}

.se-logo{
  max-height: 30px !important;
}

.sidebar-nav ul .sidebar-item .sidebar-link i {
  font-size: 18px;
}

.sidebar-nav ul .sidebar-item .sidebar-link {
  &:hover, &:active{
    color: $se-blue !important;
    & i{
      color: $se-blue !important;
    }
  }
}


.sidebar-nav ul .sidebar-item .sidebar-link.active {
  color: $se-blue !important;
  font-weight: 500;
  & i{
    color: $se-blue !important;
  }
}


.page-breadcrumb .page-title {
  font-weight: 500;
  color: $se-dark-blue;
}

.label{
  font-weight: 400;
  text-transform: uppercase;
  padding: 8px 10px;
  letter-spacing: 1px;
}

.label.label-circle {
  border-radius: 100px;
}

.label.label-primary {
  background-color: $primary;
}

.label.label-danger,
.label.label-failed{
  color: white;
  background-color: $danger;
}

.label.label-warning,
.label.label-pending,
.label.label-processing,
.label.label-on-hold{
  color: white;
  background-color: $warning;
}

.label.label-info {
  color: white;
  background-color: $info;
}

.label.label-deleted,
.label.label-cancelled,
.label.label-refunded{
  color: white;
  background-color: #707070;
}

.label.label-success,
.label.label-completed{
  color: white;
  background-color: $success;
}

.label.label-blackandwhite{
  color: white;
  background-color: #000;
}

.label.label-premium{
  color: white;
  background-color: #aaa;
}

.label.label-white,
.label.label-new {
  background-color: white;
  color: $blue !important;
}

.label.label-sm {
  padding: 4px 5px;
  font-size: 0.7rem;
}

.card-header{
  background-color: #E1E7E9;
}

.font-small{
  font-size: 0.9rem !important;
}

.fade:not(.show) {
  display: none;
}

.swal2-popup .swal2-confirm.swal2-styled{
  background-color: $primary !important;
}

.dropdown-divider {
  border-top: 1px solid #ccc;
}

.text-se{
  color: $se-blue !important;
}

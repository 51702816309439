/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

//(Required file)
@import "_variable";

//Theme colors
@import 'theme-colors/se-colors';
// Import Bootstrap source files (Required file)
@import "bootstrap/bootstrap";

//This is for the components (Required file)
@import "_components";

//This is for the pages (if you are not using some pages you can remove that style from pages folder)
@import "_pages";

//This is for the widgets (Required file)
@import "widgets/_widgets";

@import 'horizontal/_horizontal';

//This is for the responsive (Required file)
@import "_responsive";

//In This scss you can write your scss
@import "_custom";


